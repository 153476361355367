$default-color: #d23c1c;


$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #ff9800;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;

$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;


// fonts

$dark-grey:   #252423;
$light-grey:   #252422;
$red:         #DD1E35;
$light-red:   #FF5353;
$blue:        #0F75BC;
$yellow:      #FAC02E;
$green:       #35A471;
$width:       360px;
$grey:        #73706C;

.light-blue-gradient{
  background: #84feff;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg0ZmVmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0M2JmZmMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #84feff 0%, #43bffc 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#84feff), color-stop(100%,#43bffc));
  background: -webkit-linear-gradient(top, #84feff 0%,#43bffc 100%);
  background: -o-linear-gradient(top, #84feff 0%,#43bffc 100%);
  background: -ms-linear-gradient(top, #84feff 0%,#43bffc 100%);
  background: linear-gradient(to bottom, #84feff 0%,#43bffc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#84feff', endColorstr='#43bffc',GradientType=0 );
}

.blue-gradient{
  background: #0F75BC;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg0ZmVmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0M2JmZmMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #0F75BC 0%, #43BFFC 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#0F75BC), color-stop(100%,#43BFFC));
  background: -webkit-linear-gradient(top, #0F75BC 0%,#43BFFC 100%);
  background: -o-linear-gradient(top, #0F75BC 0%,#43BFFC 100%);
  background: -ms-linear-gradient(top, #0F75BC 0%,#43BFFC 100%);
  background: linear-gradient(to bottom, #0F75BC 0%,#43BFFC 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0F75BC', endColorstr='#43BFFC',GradientType=0 );
}

.light-green-gradient{
  background: #29D2B0;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg0ZmVmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0M2JmZmMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #29D2B0 0%, #35A471 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#29D2B0), color-stop(100%,#35A471));
  background: -webkit-linear-gradient(top, #29D2B0 0%,#35A471 100%);
  background: -o-linear-gradient(top, #29D2B0 0%,#35A471 100%);
  background: -ms-linear-gradient(top, #29D2B0 0%,#35A471 100%);
  background: linear-gradient(to bottom, #29D2B0 0%,#35A471 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29D2B0', endColorstr='#35A471',GradientType=0 );
}

.light-red-gradient{
  background: #FD8D7C;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg0ZmVmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0M2JmZmMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #FD8D7C 0%, #E63B48 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#FD8D7C), color-stop(100%,#E63B48));
  background: -webkit-linear-gradient(top, #FD8D7C 0%,#E63B48 100%);
  background: -o-linear-gradient(top, #FD8D7C 0%,#E63B48 100%);
  background: -ms-linear-gradient(top, #FD8D7C 0%,#E63B48 100%);
  background: linear-gradient(to bottom, #FD8D7C 0%,#E63B48 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FD8D7C', endColorstr='#E63B48',GradientType=0 );
}

.light-yellow-gradient{
  background: #FFFF73;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg0ZmVmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0M2JmZmMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #FFFF73 0%, #FAC02E 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#FFFF73), color-stop(100%,#FAC02E));
  background: -webkit-linear-gradient(top, #FFFF73 0%,#FAC02E 100%);
  background: -o-linear-gradient(top, #FFFF73 0%,#FAC02E 100%);
  background: -ms-linear-gradient(top, #FFFF73 0%,#FAC02E 100%);
  background: linear-gradient(to bottom, #FFFF73 0%,#FAC02E 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFF73', endColorstr='#FAC02E',GradientType=0 );
}
